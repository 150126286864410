<template>
    <div>
      <b-row class="no-gutters px-3 px-sm-0">
        <b-col md="8">
          <h1 class="mr-sm-4 header-tablepage">Payment</h1>
        </b-col>
      </b-row>
      <b-container class="no-gutters bg-white mt-3">
        <b-row>
          <b-col
            v-for="items of menu"
            cols="6"
            class="pointer my-3"
            @click.prevent="$router.push(items.path)"
            :key="items.id"
          >
            <div class="bg-icon shadow-sm">
              <font-awesome-icon :icon="items.icon" size="lg" />
            </div>
            <div class="content">
              <div class="ft-weight" style="text-transform: uppercase">
                {{ items.title }}
              </div>
              <div class="ft-10">{{ items.desc }}</div>
            </div>
          </b-col>
        </b-row>
      </b-container>
      <FooterAction routePath="/setting" :hideSubmit="true" />
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        menu: [
          {
            id: 0,
            title: "Payment Type",
            desc: "รูปแบบชำระเงิน",
            icon: "money-bill",
            path: "/setting/payment/payment-type",
          },
          {
            id: 1,
            title: "Return Payment",
            desc: "ช่องทางการคืนเงินลูกค้า",
            icon: "money-check",
            path: "/setting/repair/repair-cause",
          },
          {
            id: 1,
            title: "Bank",
            desc: "ช่องทางการคืนเงินลูกค้า",
            icon: "building",
            path: "/setting/repair/custom-field",
          },
        ],
      };
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .bg-icon {
    background-color: var(--theme-secondary-color);
    width: 50px;
    height: 50px;
    border-radius: 10px;
    color: var(--primary-color);
    padding: 15px;
    text-align: center;
    fill: var(--primary-color);
  }
  .content {
    position: absolute;
    top: 5px;
    margin-left: 60px;
  }
  .ft-10 {
    font-size: 10px;
  }
  .ft-weight {
    font-weight: 600;
  }
  </style>
  